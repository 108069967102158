import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <path
    stroke="currentColor"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M12.479 15.858c0-.023.005-.073.005-.095.008-.175.008-.353.008-.533V1.846h3.461c-.005 0-.046.331.042.844.104.618.388 1.5 1.148 2.37.304.328.646.618 1.019.865.128.084.26.16.397.231.885.441 1.75.576 2.175.53v3.436s-1.215-.05-2.117-.29c-1.257-.336-2.063-.853-2.063-.853s-.559-.37-.601-.395v7.1c0 .396-.105 1.383-.418 2.206a6.654 6.654 0 0 1-1.16 1.929s-.773.958-2.13 1.6c-1.223.58-2.3.567-2.622.58 0 0-1.858.075-3.534-1.064h-.008a7.035 7.035 0 0 1-1.5-1.414c-.53-.677-.855-1.479-.94-1.714v-.008c-.133-.4-.413-1.366-.37-2.298.066-1.643.617-2.656.763-2.908.384-.69.886-1.309 1.482-1.827a6.43 6.43 0 0 1 4.955-1.45l-.004 3.525a2.892 2.892 0 0 0-.9-.143 2.929 2.929 0 0 0-2.92 2.94 2.929 2.929 0 0 0 2.92 2.938c.503.001.996-.13 1.433-.377a2.939 2.939 0 0 0 1.479-2.333v-.008Zm0 0c0 .01.002-.01 0 0Z"
    fill="none"
  />,
  'Tiktok'
)
