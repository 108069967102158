import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.3999 3.40039C3.84762 3.40039 3.3999 3.84811 3.3999 4.40039V14.5917C3.3999 15.144 3.84762 15.5917 4.3999 15.5917H6.67816C7.23045 15.5917 7.67816 16.0394 7.67816 16.5917V19.1862L10.9798 15.8846C11.1673 15.697 11.4216 15.5917 11.6869 15.5917H19.5999C20.1522 15.5917 20.5999 15.144 20.5999 14.5917V4.40039C20.5999 3.84811 20.1522 3.40039 19.5999 3.40039H4.3999ZM1.3999 4.40039C1.3999 2.74353 2.74305 1.40039 4.3999 1.40039H19.5999C21.2568 1.40039 22.5999 2.74354 22.5999 4.40039V14.5917C22.5999 16.2486 21.2568 17.5917 19.5999 17.5917H12.1011L7.38527 22.3075C7.09927 22.5935 6.66915 22.679 6.29548 22.5243C5.9218 22.3695 5.67816 22.0049 5.67816 21.6004V17.5917H4.3999C2.74305 17.5917 1.3999 16.2485 1.3999 14.5917V4.40039Z"
  />,
  'MessageSquare'
)
