import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.1816 3.454C12.0759 3.38252 11.9241 3.38252 11.8184 3.454L3.49843 9.08052C3.42444 9.13055 3.40002 9.19564 3.40002 9.24418V19.7745C3.40002 20.1839 3.76398 20.6004 4.32002 20.6004H19.68C20.2361 20.6004 20.6 20.1839 20.6 19.7745V9.24418C20.6 9.19564 20.5756 9.13056 20.5016 9.08052L12.1816 3.454ZM10.698 1.79728C11.4806 1.26809 12.5195 1.2681 13.302 1.79728L21.622 7.42379C22.2233 7.83045 22.6 8.50466 22.6 9.24418V19.7745C22.6 21.3819 21.2448 22.6004 19.68 22.6004H4.32002C2.7553 22.6004 1.40002 21.3819 1.40002 19.7745V9.24418C1.40002 8.50466 1.77671 7.83045 2.37804 7.42379L10.698 1.79728Z"
  />,
  'Home3'
)
