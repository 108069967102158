import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M 11.1 3 L 9.8 4.8 c -0.2 0.4 -0.6 0.7 -1 0.9 c -0.4 0.2 -0.9 0.2 -1.3 0.2 l -2.3 -0.1 l -1.2 1.4 l 0.7 2 v 1.4 c 0 0.5 -0.3 0.9 -0.6 1.3 l -1.5 1.6 l 0.4 1.7 l 2 0.7 c 0.5 0.2 0.9 0.4 1.1 0.8 a 2.6 2.6 0 0 1 0.1 0 c 0.3 0.4 0.5 0.8 0.6 1.3 l 0.3 2.1 l 1.7 0.8 l 2 -1 a 2.7 2.7 0 0 1 1.2 -0.4 c 0.5 0 1 0.1 1.4 0.3 l 2 1.1 l 1.6 -0.8 l 0.4 -2 a 2.6 2.6 0 0 1 1.6 -2.1 l 2.1 -0.9 l 0.4 -1.7 l -1.5 -1.6 a 2.7 2.7 0 0 1 -0.7 -1.2 V 9.2 l 0.7 -2 l -1.1 -1.5 l -2.3 0.2 A 2.7 2.7 0 0 1 14.3 4.8 L 13 3 h -1.9 Z m 4.7 0.7 L 14.3 1.5 A 1 1 0 0 0 13.3 1 h -2.7 a 1 1 0 0 0 -0.9 0.5 L 8.3 3.7 l -0.3 0.2 c -0.1 0 -0.3 0.1 -0.4 0 H 4.8 a 1 1 0 0 0 -0.8 0.3 L 2.2 6.4 a 1 1 0 0 0 -0.2 1 l 0.9 2.4 v 0.4 l -0.2 0.3 l -1.8 2 a 1 1 0 0 0 -0.3 1 L 1.3 16 c 0 0.3 0.3 0.6 0.6 0.7 l 2.5 1 l 0.3 0.1 a 0.7 0.7 0 0 1 0.2 0.4 l 0.4 2.6 c 0 0.4 0.3 0.6 0.6 0.8 L 8.3 22.9 h 1 l 2.3 -1.4 a 0.8 0.8 0 0 1 0.6 0 h 0.1 l 2.5 1.3 c 0.3 0.2 0.6 0.2 0.9 0 l 2.5 -1.1 c 0.3 -0.2 0.5 -0.5 0.6 -0.8 l 0.4 -2.6 a 0.7 0.7 0 0 1 0.4 -0.5 l 2.6 -1 c 0.3 -0.2 0.5 -0.4 0.6 -0.8 l 0.6 -2.6 c 0 -0.3 0 -0.7 -0.3 -1 l -1.8 -2 a 0.8 0.8 0 0 1 -0.2 -0.3 v -0.3 l 0.8 -2.6 a 1 1 0 0 0 -0.2 -1 l -1.7 -2 a 1 1 0 0 0 -0.9 -0.4 l -2.7 0.2 h -0.4 a 0.8 0.8 0 0 1 -0.3 -0.3 Z m -3 15.9 l -0.3 1 l 0.3 -1 Z m -0.8 -10 A 2.5 2.5 0 0 0 9.5 12 c 0 1.3 1 2.4 2.5 2.4 c 1.4 0 2.5 -1 2.5 -2.4 c 0 -1.3 -1.1 -2.4 -2.5 -2.4 Z M 7.6 12 c 0 -2.4 2 -4.3 4.4 -4.3 C 14.3 7.7 16.3 9.6 16.3 12 s -2 4.3 -4.4 4.3 c -2.4 0 -4.4 -1.9 -4.4 -4.3 Z"
  />,
  'Settings'
)
