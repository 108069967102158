import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.514 10.597a8.66 8.66 0 0 0 .12 3.405.977.977 0 0 1 .067-.002h3.186a22.74 22.74 0 0 1-.045-3.4H3.6a.98.98 0 0 1-.086-.003ZM4.098 8.6h2.96c.223-1.42.584-2.71 1.056-3.789.095-.216.195-.427.301-.63A8.632 8.632 0 0 0 4.098 8.6ZM12 1.4C6.146 1.4 1.4 6.146 1.4 12c0 5.855 4.746 10.6 10.6 10.6 5.854 0 10.6-4.745 10.6-10.6 0-5.854-4.746-10.6-10.6-10.6Zm0 2c-.249 0-.565.112-.94.474-.38.367-.767.946-1.114 1.739-.363.83-.66 1.844-.86 2.987h5.829c-.201-1.143-.498-2.158-.861-2.987-.347-.793-.735-1.372-1.114-1.74-.375-.361-.691-.473-.94-.473Zm4.942 5.2c-.223-1.42-.584-2.71-1.056-3.789a10.241 10.241 0 0 0-.301-.63 8.632 8.632 0 0 1 4.317 4.42h-2.96Zm-1.789 2H8.846a20.93 20.93 0 0 0 .05 3.4h6.208a20.65 20.65 0 0 0 .05-3.4Zm1.96 3.4a22.736 22.736 0 0 0 .045-3.4H20.4a.978.978 0 0 0 .086-.003 8.66 8.66 0 0 1-.12 3.403h-3.253Zm-2.315 2H9.202c.192.9.446 1.706.744 2.388.347.793.735 1.372 1.114 1.739.375.362.691.473.94.473s.565-.11.94-.473c.38-.367.767-.946 1.114-1.74A12.56 12.56 0 0 0 14.798 16Zm.787 3.82c.106-.203.206-.414.301-.63.404-.925.727-2.005.952-3.19h2.777a8.636 8.636 0 0 1-4.03 3.82Zm-7.17 0a10.263 10.263 0 0 1-.301-.63c-.404-.925-.727-2.005-.952-3.19H4.385a8.636 8.636 0 0 0 4.03 3.82Z"
  />,
  'Globe3'
)
