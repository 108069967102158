import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.55737 10.3174C6.23582 10.0692 6.9686 9.93372 7.73302 9.93372C11.2306 9.93372 14.0659 12.7693 14.0659 16.2671C14.0659 17.0313 13.9305 17.7639 13.6825 18.4422C14.7922 18.3525 15.8322 18.0226 16.7525 17.5051C16.9752 17.3799 17.2373 17.3446 17.4852 17.4066L20.4005 18.1354L19.8844 14.5222C19.8579 14.3368 19.884 14.1477 19.9597 13.9764C20.3705 13.0472 20.5992 12.0185 20.5992 10.9337C20.5992 6.77318 17.2264 3.40039 13.0659 3.40039C9.11282 3.40039 5.87093 6.44512 5.55737 10.3174ZM12.5812 20.3419C11.4195 21.7228 9.67875 22.6004 7.73302 22.6004C6.75521 22.6004 5.82704 22.3781 4.99834 21.9808L2.64174 22.57C2.31873 22.6508 1.97671 22.5655 1.72946 22.3425C1.4822 22.1195 1.36215 21.7881 1.40923 21.4585L1.82461 18.5506C1.55028 17.841 1.40019 17.0705 1.40019 16.2671C1.40019 14.3215 2.27742 12.5808 3.65793 11.419C3.57803 11.2753 3.53252 11.1098 3.53252 10.9337C3.53252 5.66861 7.80074 1.40039 13.0659 1.40039C18.331 1.40039 22.5992 5.66861 22.5992 10.9337C22.5992 12.1984 22.3524 13.4078 21.9036 14.5145L22.5908 19.3248C22.6378 19.6544 22.5178 19.9859 22.2705 20.2088C22.0233 20.4318 21.6813 20.5171 21.3583 20.4364L17.3738 19.4403C16.079 20.0971 14.6144 20.4671 13.0659 20.4671C12.89 20.4671 12.7247 20.4217 12.5812 20.3419ZM7.73302 11.9337C5.34016 11.9337 3.40019 13.8737 3.40019 16.2671C3.40019 16.8918 3.53179 17.4832 3.76782 18.0172C3.84353 18.1884 3.86963 18.3775 3.84315 18.5629L3.59944 20.269L4.88022 19.9488C5.12809 19.8868 5.39029 19.9221 5.61299 20.0473C6.23878 20.3993 6.96091 20.6004 7.73302 20.6004C10.1259 20.6004 12.0659 18.6604 12.0659 16.2671C12.0659 13.8737 10.1259 11.9337 7.73302 11.9337Z"
  />,
  'MessageChat2'
)
