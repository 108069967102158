import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.1628 2.88679C9.43083 2.52081 8.56927 2.52081 7.83729 2.88679L2.80005 5.40542V12.8154C2.80005 13.5079 3.21818 14.4274 4.37801 15.6206C5.42754 16.7003 6.97243 17.8891 9.00213 19.202C11.0629 17.8894 12.5985 16.835 13.6439 15.8238C14.7507 14.7532 15.2001 13.8351 15.2001 12.8154V5.40542L10.1628 2.88679ZM17.2001 4.78744V12.8154C17.2001 14.5677 16.3682 15.9711 15.0344 17.2613C13.7279 18.5251 11.839 19.7772 9.53402 21.2329C9.20784 21.4389 8.79226 21.4389 8.46608 21.2329C6.12195 19.7525 4.24512 18.3532 2.94389 17.0146C1.66312 15.697 0.800049 14.2949 0.800049 12.8154V4.78738C0.800049 4.40861 1.01405 4.06235 1.35284 3.89296L6.94287 1.09794C8.23789 0.450427 9.76221 0.450426 11.0572 1.09794L16.6473 3.89296C16.986 4.06235 17.2001 4.40867 17.2001 4.78744ZM12.7072 7.68028C13.0977 8.0708 13.0977 8.70397 12.7072 9.09449L9.10716 12.6945C8.71663 13.085 8.08347 13.085 7.69294 12.6945L5.89294 10.8945C5.50242 10.504 5.50242 9.8708 5.89294 9.48028C6.28347 9.08975 6.91663 9.08975 7.30716 9.48028L8.40005 10.5732L11.2929 7.68028C11.6835 7.28975 12.3166 7.28975 12.7072 7.68028Z"
  />,
  'ShieldCheck'
)
