import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.618 13.248h2.218c.316 0 .574-.251.574-.558v-.046a.567.567 0 0 0-.574-.558H6.24V8.557A.567.567 0 0 0 5.666 8h-.048a.567.567 0 0 0-.574.557v4.133c0 .307.258.558.574.558Zm13.747-2.591v-.047a.567.567 0 0 0-.574-.557h-1.597v-.882h1.597c.316 0 .574-.25.574-.557v-.047a.567.567 0 0 0-.574-.557h-2.218a.567.567 0 0 0-.574.557V12.7c0 .307.258.557.574.557h2.218c.316 0 .574-.25.574-.557v-.046a.567.567 0 0 0-.574-.558h-1.597v-.882h1.597c.316 0 .574-.25.574-.557Zm-4.266 2.424a.547.547 0 0 0 .166-.39V8.556A.567.567 0 0 0 14.69 8h-.048a.567.567 0 0 0-.574.557v2.426l-2.073-2.701A.578.578 0 0 0 11.498 8h-.048a.567.567 0 0 0-.574.557v4.133c0 .307.258.558.574.558h.048c.315 0 .573-.251.573-.558V10.22l2.089 2.77c.013.02.027.039.042.056a.48.48 0 0 0 .223.16c.067.027.141.043.218.043h.048a.582.582 0 0 0 .268-.066.4.4 0 0 0 .14-.1Zm-5.652.167h.048c.315 0 .573-.251.573-.558V8.557A.567.567 0 0 0 9.495 8h-.048a.567.567 0 0 0-.574.557v4.133c0 .307.258.558.574.558Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9.999 17.595C9.453 16.633 2 17.108 2 10.784 2 6.484 6.477 3 12 3s10 3.485 10 7.784C22 15.083 15 19.54 9.999 21v-3.405Z"
      fill="none"
    />
  </>,
  'Line'
)
