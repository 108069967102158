import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17.9999 2.7998C16.564 2.7998 15.3999 3.96386 15.3999 5.39981C15.3999 6.83575 16.564 7.9998 17.9999 7.9998C19.4358 7.9998 20.5999 6.83575 20.5999 5.39981C20.5999 3.96386 19.4358 2.7998 17.9999 2.7998ZM13.3999 5.39981C13.3999 2.8593 15.4594 0.799805 17.9999 0.799805C20.5404 0.799805 22.5999 2.8593 22.5999 5.39981C22.5999 7.94032 20.5404 9.99981 17.9999 9.99981C15.4594 9.99981 13.3999 7.94032 13.3999 5.39981ZM4.7999 3.9998C4.0267 3.9998 3.3999 4.62661 3.3999 5.39981V14.7911C3.3999 15.5643 4.0267 16.1911 4.7999 16.1911H6.67816C7.23045 16.1911 7.67816 16.6388 7.67816 17.1911V19.7856L10.9798 16.484C11.1673 16.2965 11.4216 16.1911 11.6869 16.1911H19.1999C19.9731 16.1911 20.5999 15.5643 20.5999 14.7911V11.9998C20.5999 11.4475 21.0476 10.9998 21.5999 10.9998C22.1522 10.9998 22.5999 11.4475 22.5999 11.9998V14.7911C22.5999 16.6689 21.0777 18.1911 19.1999 18.1911H12.1011L7.38527 22.9069C7.09927 23.1929 6.66915 23.2785 6.29548 23.1237C5.9218 22.9689 5.67816 22.6043 5.67816 22.1998V18.1911H4.7999C2.92214 18.1911 1.3999 16.6689 1.3999 14.7911V5.39981C1.3999 3.52204 2.92213 1.9998 4.7999 1.9998H10.7999C11.3522 1.9998 11.7999 2.44752 11.7999 2.9998C11.7999 3.55209 11.3522 3.9998 10.7999 3.9998H4.7999Z"
  />,
  'MessageNotifySquare'
)
