import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M1.4 12C1.4 6.146 6.146 1.4 12 1.4c5.854 0 10.6 4.746 10.6 10.6 0 5.855-4.746 10.6-10.6 10.6-5.854 0-10.6-4.745-10.6-10.6ZM12 3.4a8.6 8.6 0 1 0 0 17.2 8.6 8.6 0 0 0 0-17.2Zm4.058 5.742a1 1 0 0 1 0 1.414l-4.551 4.552a1 1 0 0 1-1.414 0L8.54 13.556a1 1 0 1 1 1.415-1.414l.844.844 3.844-3.844a1 1 0 0 1 1.414 0Z"
  />,
  'CheckContained'
)
