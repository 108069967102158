import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <>
    <path
      fill="currentColor"
      d="m9.406 2.34-.681.732a1 1 0 0 0 .94.234l-.259-.966ZM21.66 14.594l-.966-.259a1 1 0 0 0 .234.94l.732-.681Zm-7.066 7.066.681-.732a1 1 0 0 0-.94-.234l.259.966ZM2.34 9.406l.966.259a1 1 0 0 0-.234-.94l-.732.681ZM12 1c-.985 0-1.942.13-2.852.374l.517 1.932A9.013 9.013 0 0 1 12 3V1Zm11 11c0-6.075-4.925-11-11-11v2a9 9 0 0 1 9 9h2Zm-.374 2.852c.244-.91.374-1.867.374-2.852h-2c0 .809-.107 1.591-.306 2.335l1.932.517ZM24 18a5.982 5.982 0 0 0-1.608-4.088l-1.464 1.363A3.983 3.983 0 0 1 22 18h2Zm-6 6a6 6 0 0 0 6-6h-2a4 4 0 0 1-4 4v2Zm-4.088-1.608A5.982 5.982 0 0 0 18 24v-2a3.983 3.983 0 0 1-2.725-1.072l-1.363 1.464ZM12 23c.985 0 1.942-.13 2.852-.374l-.517-1.932c-.744.2-1.526.306-2.335.306v2ZM1 12c0 6.075 4.925 11 11 11v-2a9 9 0 0 1-9-9H1Zm.374-2.852C1.13 10.058 1 11.015 1 12h2c0-.809.106-1.591.306-2.335l-1.932-.517Zm1.698-.423A3.982 3.982 0 0 1 2 6H0c0 1.579.611 3.017 1.608 4.088l1.464-1.363ZM2 6a4 4 0 0 1 4-4V0a6 6 0 0 0-6 6h2Zm4-4c1.053 0 2.01.406 2.725 1.072l1.363-1.464A5.982 5.982 0 0 0 6 0v2Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.111 6.998A3.813 3.813 0 0 1 11.7 6h.64a3.84 3.84 0 0 1 2.016.664 3.524 3.524 0 0 1 1.309 1.647 1 1 0 1 1-1.87.711 1.524 1.524 0 0 0-.57-.709A1.838 1.838 0 0 0 12.278 8H11.7c-.475 0-.916.175-1.23.466A1.41 1.41 0 0 0 10 9.5c0 .372.159.746.47 1.034.314.29.755.466 1.23.466h.6c.957 0 1.89.351 2.589.998A3.41 3.41 0 0 1 16 14.5a3.41 3.41 0 0 1-1.111 2.502A3.813 3.813 0 0 1 12.3 18h-.64a3.84 3.84 0 0 1-2.016-.664 3.523 3.523 0 0 1-1.309-1.647 1 1 0 0 1 1.87-.711c.103.271.297.521.57.709.269.184.598.296.947.313h.578c.475 0 .916-.175 1.23-.466A1.41 1.41 0 0 0 14 14.5a1.41 1.41 0 0 0-.47-1.034A1.814 1.814 0 0 0 12.3 13h-.6c-.957 0-1.89-.351-2.589-.998A3.41 3.41 0 0 1 8 9.5c0-.954.41-1.852 1.111-2.502Z"
      clipRule="evenodd"
    />
  </>,
  'Skype'
)
