import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12.182 3.454a.334.334 0 0 0-.364 0l-8.32 5.627c-.074.05-.098.115-.098.163v10.53c0 .41.364.826.92.826H7.8v-6.303c0-1.272 1.069-2.218 2.28-2.218h3.84c1.211 0 2.28.946 2.28 2.218V20.6h3.48c.556 0 .92-.416.92-.826V9.245c0-.048-.024-.113-.098-.163l-8.32-5.627ZM19.68 22.6c1.565 0 2.92-1.218 2.92-2.826V9.245a2.2 2.2 0 0 0-.978-1.82l-8.32-5.627a2.334 2.334 0 0 0-2.604 0l-8.32 5.627a2.2 2.2 0 0 0-.978 1.82v10.53c0 1.608 1.355 2.826 2.92 2.826h15.36Zm-5.48-2v-6.303c0-.074-.077-.218-.28-.218h-3.84c-.203 0-.28.144-.28.218V20.6h4.4Z"
  />,
  'Home4'
)
