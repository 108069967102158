import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6371 2.72065C13.505 1.74418 14.9417 1.7674 15.7947 2.74548L15.8003 2.75185C16.2989 3.3334 16.4782 4.11508 16.3532 4.844L16.3521 4.85009L15.8047 7.92803H19.1098C20.3983 7.92803 21.4139 8.70106 21.9717 9.6869C22.5291 10.6721 22.6853 11.9468 22.2471 13.1515L22.246 13.1544L19.6478 20.2282C19.2763 21.2166 18.3791 22 17.2466 22H9.09243C7.52552 22 6.5 20.5667 6.5 19.1428V9.86593C6.5 9.1571 6.73883 8.44662 7.20265 7.90567C7.22452 7.88017 7.24766 7.85578 7.27199 7.8326L12.6371 2.72065ZM14.1864 4.00464C14.177 4.0092 14.1547 4.02224 14.1257 4.05656C14.1023 4.0844 14.0773 4.11094 14.0509 4.13606L8.69611 9.23818C8.58317 9.38571 8.5 9.60894 8.5 9.86593V19.1428C8.5 19.7618 8.90766 20 9.09243 20H17.2466C17.3878 20 17.6362 19.8925 17.7745 19.5275C17.7749 19.5265 17.7752 19.5255 17.7756 19.5245L20.3676 12.4678C20.3678 12.4673 20.3679 12.4668 20.3681 12.4663C20.598 11.8328 20.5113 11.1673 20.231 10.6718C19.9507 10.1765 19.5331 9.92803 19.1098 9.92803H14.6112C14.3153 9.92803 14.0347 9.79703 13.8447 9.57025C13.6546 9.34348 13.5748 9.04421 13.6266 8.75293L14.3824 4.50354C14.4153 4.30732 14.3577 4.14436 14.2846 4.05686C14.2556 4.0244 14.2326 4.01091 14.2213 4.00558C14.2094 4.00004 14.2033 3.99999 14.202 3.99999C14.2007 4 14.196 4.00002 14.1864 4.00464Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 11C2.5 9.34315 3.84315 8 5.5 8C7.15685 8 8.5 9.34315 8.5 11V19C8.5 20.6569 7.15685 22 5.5 22C3.84315 22 2.5 20.6569 2.5 19V11ZM5.5 10C4.94772 10 4.5 10.4477 4.5 11V19C4.5 19.5523 4.94772 20 5.5 20C6.05228 20 6.5 19.5523 6.5 19V11C6.5 10.4477 6.05228 10 5.5 10Z"
    />
  </>,
  'ThumbsUp'
)
