import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10.927 4.219a1 1 0 0 1 1.025.047l9.6 6.359a1 1 0 0 1 .035 1.643l-9.6 6.961a1 1 0 0 1-1.587-.81V15.65c-1.684.05-3.201.826-4.4 1.725a12.74 12.74 0 0 0-2.066 1.958 6.568 6.568 0 0 0-.107.131l-.023.03-.004.006a1.001 1.001 0 0 1-1.8-.6h1c-1 0-1 0-1-.002v-.039l.002-.087a12.1 12.1 0 0 1 .141-1.372c.142-.878.425-2.074.996-3.337 1.084-2.401 3.208-5.03 7.261-6.148V5.1a1 1 0 0 1 .527-.881ZM4.54 15.974c.085-.066.171-.133.26-.2 1.597-1.197 3.956-2.413 6.724-2.067a1 1 0 0 1 .876.993v1.76l6.845-4.964L12.4 6.96V8.7a1 1 0 0 1-.79.977c-3.83.82-5.702 3.112-6.649 5.21-.167.37-.306.736-.42 1.087Z"
  />,
  'Share'
)
