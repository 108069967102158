import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m7.01 22 4.697-4.217H18a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H6a3 3 0 0 0-3 3v9.783a3 3 0 0 0 3 3h1.01V22Z"
      fill="none"
    />
    <path
      fill="currentColor"
      d="M14.895 14.897s-.588.571-.732.738a1.127 1.127 0 0 1-.873.365c-.035 0-.072 0-.107-.002-.69-.044-1.331-.31-1.812-.537a10.301 10.301 0 0 1-3.43-2.65c-.792-.943-1.322-1.814-1.673-2.75-.216-.57-.295-1.016-.26-1.435.023-.269.128-.491.32-.681l.793-.782A.531.531 0 0 1 7.474 7a.5.5 0 0 1 .34.16l.006.007c.142.131.277.266.418.41l.221.223.635.626c.246.243.246.468 0 .711-.068.067-.133.133-.2.197-.195.197-.042.046-.244.225-.005.005-.01.007-.012.011-.2.198-.163.39-.12.521l.006.02c.165.395.398.766.75 1.209l.003.002c.641.78 1.318 1.388 2.063 1.853.096.06.193.108.286.154.084.041.163.08.23.121l.028.016a.508.508 0 0 0 .23.058.5.5 0 0 0 .353-.159l.456-.45c.079-.077.204-.171.35-.171a.475.475 0 0 1 .34.172l1.28 1.263c.24.234.002.718.002.718Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.817 4.79a1 1 0 0 1 1.23-.697c1.404.389 2.654 1.13 3.603 2.152l.001.001c.952 1.029 1.53 2.272 1.625 3.601a1 1 0 0 1-1.995.142c-.059-.825-.42-1.651-1.097-2.384-.681-.733-1.6-1.288-2.67-1.584a1 1 0 0 1-.697-1.23Zm-.473 2.781a1 1 0 0 1 1.23-.698 4.734 4.734 0 0 1 2.229 1.335c.595.643.965 1.43 1.028 2.286a1 1 0 1 1-1.994.147c-.026-.357-.181-.729-.502-1.075a2.735 2.735 0 0 0-1.293-.765 1 1 0 0 1-.698-1.23Z"
      clipRule="evenodd"
    />
  </>,
  'Viber'
)
