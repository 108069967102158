import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17.9992 2.7998C16.5632 2.7998 15.3992 3.96386 15.3992 5.39981C15.3992 6.83575 16.5632 7.9998 17.9992 7.9998C19.4351 7.9998 20.5992 6.83575 20.5992 5.39981C20.5992 3.96386 19.4351 2.7998 17.9992 2.7998ZM13.3992 5.39981C13.3992 2.8593 15.4587 0.799805 17.9992 0.799805C20.5397 0.799805 22.5992 2.8593 22.5992 5.39981C22.5992 7.94032 20.5397 9.99981 17.9992 9.99981C15.4587 9.99981 13.3992 7.94032 13.3992 5.39981ZM11.1816 2.98191C11.2846 3.52452 10.9281 4.04783 10.3855 4.15076C6.40721 4.90542 3.39917 8.4024 3.39917 12.5998C3.39917 17.3495 7.24952 21.1998 11.9992 21.1998C13.5301 21.1998 14.9647 20.8007 16.2079 20.1015C16.4306 19.9763 16.6927 19.9411 16.9406 20.003L20.4007 20.8681L19.7937 16.6192C19.7672 16.4338 19.7934 16.2447 19.8691 16.0734C20.3381 15.0124 20.5992 13.8379 20.5992 12.5998C20.5992 12.0475 21.0469 11.5998 21.5992 11.5998C22.1515 11.5998 22.5992 12.0475 22.5992 12.5998C22.5992 14.0181 22.3201 15.3735 21.8131 16.6124L22.5909 22.0575C22.638 22.3871 22.518 22.7185 22.2707 22.9415C22.0235 23.1645 21.6815 23.2498 21.3585 23.169L16.8305 22.037C15.3808 22.7805 13.7376 23.1998 11.9992 23.1998C6.14495 23.1998 1.39917 18.454 1.39917 12.5998C1.39917 7.4238 5.10803 3.1162 10.0128 2.1858C10.5554 2.08287 11.0787 2.4393 11.1816 2.98191Z"
  />,
  'MessageNotifyCircle'
)
