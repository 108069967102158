import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12 3.4a8.6 8.6 0 0 0 0 17.2c1.53 0 2.965-.399 4.208-1.098a1 1 0 0 1 .733-.098l3.46.865-.607-4.25a1 1 0 0 1 .075-.545c.47-1.061.73-2.236.73-3.474A8.6 8.6 0 0 0 12 3.4ZM1.4 12C1.4 6.146 6.144 1.4 12 1.4c5.854 0 10.6 4.746 10.6 10.6 0 1.419-.28 2.774-.787 4.013l.778 5.445a1 1 0 0 1-1.232 1.112l-4.528-1.132a10.559 10.559 0 0 1-4.832 1.162C6.145 22.6 1.4 17.855 1.4 12Zm5.8-1a1 1 0 0 1 1 1v.09a1 1 0 1 1-2 0V12a1 1 0 0 1 1-1Zm4.8 0a1 1 0 0 1 1 1v.09a1 1 0 1 1-2 0V12a1 1 0 0 1 1-1Zm4.8 0a1 1 0 0 1 1 1v.09a1 1 0 1 1-2 0V12a1 1 0 0 1 1-1Z"
  />,
  'MessageTyping'
)
