import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M 7.495 6.5279 C 6.7642 4.8576 8.5079 3.1808 10.1482 3.9761 L 22.6149 10.0206 C 24.0896 10.7356 24.0896 12.8363 22.6149 13.5513 L 10.1482 19.5958 C 8.5079 20.3911 6.7642 18.7143 7.495 17.0441 L 9.7811 11.8185 L 9.7887 11.8216 L 9.7811 11.8185 C 9.7902 11.7978 9.7902 11.7742 9.7811 11.7535 L 7.495 6.5279 Z M 11.437 12.7265 L 9.2182 17.798 C 9.2102 17.8164 9.2085 17.8275 9.2081 17.8321 C 9.2076 17.8371 9.2079 17.841 9.2089 17.8451 C 9.211 17.8544 9.2184 17.8715 9.2362 17.8887 C 9.2541 17.9059 9.2716 17.9126 9.281 17.9144 C 9.2851 17.9151 9.289 17.9153 9.2939 17.9146 C 9.2985 17.914 9.3097 17.912 9.3277 17.9032 L 20.0047 12.7265 L 11.437 12.7265 Z M 20.0046 10.8455 L 11.437 10.8455 L 9.2182 5.774 C 9.2102 5.7556 9.2085 5.7445 9.2081 5.7398 C 9.2076 5.7349 9.2079 5.7309 9.2089 5.7268 C 9.211 5.7175 9.2184 5.7004 9.2362 5.6832 C 9.2541 5.666 9.2716 5.6594 9.281 5.6576 C 9.2851 5.6568 9.289 5.6567 9.2939 5.6573 C 9.2985 5.6579 9.3097 5.66 9.3277 5.6688 L 20.0046 10.8455 Z M 2.4891 8.1402 C 2.4891 7.6207 2.9103 7.1997 3.4296 7.1997 H 5.7191 C 6.2386 7.1997 6.6596 7.6207 6.6596 8.1402 C 6.6596 8.6596 6.2386 9.0807 5.7191 9.0807 H 3.4296 C 2.9103 9.0807 2.4891 8.6596 2.4891 8.1402 Z M 0.2319 11.9237 C 0.2319 11.4043 0.6531 10.9832 1.1724 10.9832 H 5.7191 C 6.2386 10.9832 6.6596 11.4043 6.6596 11.9237 C 6.6596 12.4431 6.2386 12.8642 5.7191 12.8642 H 1.1724 C 0.6531 12.8642 0.2319 12.4431 0.2319 11.9237 Z M 2.4891 15.7072 C 2.4891 15.1878 2.9103 14.7667 3.4296 14.7667 H 5.7191 C 6.2386 14.7667 6.6596 15.1878 6.6596 15.7072 C 6.6596 16.2267 6.2386 16.6477 5.7191 16.6477 H 3.4296 C 2.9103 16.6477 2.4891 16.2267 2.4891 15.7072 Z"
  />,
  'Send2'
)
