import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2Zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Z"
  />,
  'More'
)
