import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M6 3.4A2.6 2.6 0 0 0 3.4 6v12A2.6 2.6 0 0 0 6 20.6h12a2.6 2.6 0 0 0 2.6-2.6V6A2.6 2.6 0 0 0 18 3.4H6ZM1.4 6A4.6 4.6 0 0 1 6 1.4h12A4.6 4.6 0 0 1 22.6 6v12a4.6 4.6 0 0 1-4.6 4.6H6A4.6 4.6 0 0 1 1.4 18V6Zm10.601 1.6c-.756 0-1.4.63-1.4 1.446a1 1 0 0 1-2 0c0-1.885 1.505-3.446 3.4-3.446s3.4 1.56 3.4 3.446a3.444 3.444 0 0 1-2.4 3.294l-.001.781a1 1 0 1 1-2 0v-1.153l.001-.478a1 1 0 0 1 1-.999c.756 0 1.4-.63 1.4-1.445 0-.816-.644-1.446-1.4-1.446ZM12 15.8a1 1 0 0 1 1 1v.043a1 1 0 1 1-2 0V16.8a1 1 0 0 1 1-1Z"
  />,
  'HelpSquareContained'
)
