import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.576 10.262h2.717A1.976 1.976 0 0 0 7.69 6.889L6.333 5.532a8.592 8.592 0 0 0-2.757 4.73ZM8.009 4.38l1.095 1.095a3.976 3.976 0 0 1-2.81 6.787h-2.89a8.6 8.6 0 0 0 14.446 6.043l-2.62-3.09h-1.753a1 1 0 0 1-.707-.292l-1.477-1.477a1 1 0 0 1-.125-1.262l2.954-4.43a1 1 0 0 1 .832-.446h4.254A8.593 8.593 0 0 0 12 3.4c-1.44 0-2.798.354-3.99.98Zm12.16 4.927-.046.001H15.49l-2.202 3.303.604.605h1.801a1 1 0 0 1 .763.353l2.708 3.192a8.56 8.56 0 0 0 1.437-4.76c0-.941-.151-1.847-.43-2.694Zm-.208 9.692A10.56 10.56 0 0 0 22.6 12c0-5.854-4.746-10.6-10.6-10.6C6.146 1.4 1.4 6.146 1.4 12c0 5.855 4.746 10.6 10.6 10.6A10.575 10.575 0 0 0 19.961 19Z"
  />,
  'Globe2'
)
