import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <>
    <circle cx="12" cy="12" r="10" fill="#47ACDF" />
    <path
      d="M 10.4207 16.5 C 9.2077 16.5 8.0768 16.1471 7.125 15.5383 C 7.9331 15.5907 9.3593 15.4654 10.2463 14.6193 C 8.9119 14.558 8.3101 13.5346 8.2316 13.0972 C 8.345 13.141 8.8857 13.1935 9.191 13.071 C 7.656 12.6861 7.4204 11.339 7.4728 10.9279 C 7.7607 11.1291 8.249 11.199 8.4409 11.1815 C 7.0106 10.1581 7.5252 8.6185 7.778 8.2862 C 8.8045 9.7083 10.343 10.5069 12.2462 10.5514 C 12.2103 10.3941 12.1913 10.2302 12.1913 10.0618 C 12.1913 8.8541 13.1675 7.875 14.3718 7.875 C 15.0009 7.875 15.5679 8.1423 15.9659 8.5698 C 16.3864 8.4713 17.0191 8.2407 17.3285 8.0412 C 17.1725 8.601 16.6871 9.068 16.3934 9.2412 C 16.3959 9.2471 16.391 9.2353 16.3934 9.2412 C 16.6514 9.2022 17.3493 9.068 17.625 8.8809 C 17.4886 9.1955 16.974 9.7184 16.5517 10.0111 C 16.6303 13.4768 13.9787 16.5 10.4207 16.5 Z"
      fill="#fff"
    />
  </>,
  'TwitterLogo'
)
